<template>
	<div class="showDomesticFlight-box">
		<div class="container" style="min-height: 500px;">
			<div class="search-list-box">
				<desAirSearch @bindSearchFlight="bindSearchFlight" :showcity="showCity"></desAirSearch>
			</div>
			<!-- <div class="notice pd10 hide">
				<span class="iconfont icon-hint icon-03tanhao"></span>
				重要公告：疫情期间各国及地区入境政策变化频繁,客服电话呼入激增,为更快解决您的问题,建议优先使用自助方式查询相关入境政策,点此详情查看。
				<span class="fr detail">详情</span>
			</div> -->
			<div class="notice pd10 gray0" style="" id="box_yiqinggonggao" v-if="GetGongGaoInfo!=null">
				<div class="ofh">
					<div class="fl"><span class="iconfont icon-zhongyaotishi icon-notice">紧急公告：</span></div>
					<div class="fl ft14 " style="margin-top: 3px;">
						{{GetGongGaoInfo.Title}}
						<a href="javascript:;" @click="showGongGao=true" class="ml0">查看详情</a>
					</div>
				</div>
			</div>
			<!--预订的航班和筛选-->
			<el-row>
				<div class="select-flightbox">
					<div class="mt10" v-for="(item, index) in BookFlightRouting" :key="index">
						<el-row type="flex" class="block-title border-bottom">
							<span class="fb mr20" v-if="TicketSearchInfo.Dual==2">已选：第{{ index + 1 }}程</span>
							<span class="fb mr20" v-else>已选：{{ item.SegRef==1?'去':'回'}}程</span>
							<span class="ml20 mr20">{{ item.DepartureName }}-{{ item.ArriveName }}</span>
							<span class="ml20">{{ item.DepartureDate }}</span>
						</el-row>
						<el-row type="flex" justify="space-between" align="middle" class="flight-i">
							<div class="airbox">
								<div class="air">
									<el-image class="logo" :src="item.AirlineImg"></el-image>
									{{ item.MarktingAirlineName }}
								</div>
								<span>{{item.trips[0].FlightNO}}丨 机型 {{item.trips[0].AirplaneKind}}</span>
							</div>
							<el-row type="flex">
								<div class="timebox tr">
									<div class="time" style="text-align:right;">{{ item.trips[0].DepartureTime }}</div>
									<div class="airport">
										{{ item.trips[0].DepartureName }}{{ item.trips[0].DepartureTerminal }}
									</div>
								</div>
								<div class="translatebox">
									<i class="change-num" v-if="item.trips[0].StopAirport != ''">经停</i>
									<i class="change-num" v-else-if="item.trips[0].Stop != ''&&item.trips[0].Stop != '0'">经停{{item.trips[0].Stop}}次</i>
									<i class="change-num"
										v-else-if="item.TurnaroundCount != ''">转机{{item.TurnaroundCount}}次</i>
									<i class="change-num" v-else>直飞</i>
									<em class="change-sym" style="width: 120px;"></em>
									<i class="change-city"
										v-if="item.trips[0].StopAirport != ''">{{ item.trips[0].StopAirportName }}</i>
									<i class="change-city"
										v-else-if="item.TurnaroundCity != ''">{{ item.TurnaroundCity }}</i>
								</div>
								<div class="timebox">
									<div class="time">{{ item.trips[item.trips.length - 1].ArriveTime }}<span
											class="red">{{ item.days }}</span></div>
									<div class="airport">
										{{ item.trips[item.trips.length - 1].ArriveName }}{{ item.trips[item.trips.length - 1].ArrivalTerminal }}
									</div>
								</div>
							</el-row>
							<div class="durationbox">
								<span class="gray6">总时长:</span>
								{{ item.trips[0].Lishi }}
							</div>
							<div class="pricebox"><span class="price ft14">￥{{ item.TotalPrice }}</span></div>
							<div class="btnbox"><a class="reset-btn" @click="bindRemoveBookFlight">[重新选择]</a></div>
						</el-row>
					</div>

					<div class="flight-route">
						<el-row class="select-tit" type="flex" justify="space-between">
							<div class="load-animation" v-if="isShowFlightLoading">
								<div class="line"></div>
								<img class="animation" src="../../assets/slider.png" />
							</div>
							<div>
								<span
									class="tit">{{SegRef==1?'去':'回'}}：{{ TicketSearchInfo.MultSegments[SegRef - 1].DepartInputVal }}--
									{{ TicketSearchInfo.MultSegments[SegRef - 1].ArrivalInputVal }}</span>
								<span
									class="ml20 mr20 ft16">{{ TicketSearchInfo.MultSegments[SegRef - 1].DepartDateStr }}</span>
							</div>
							<div>
								<span v-if="!isDoneData">
									已查询到{{ searchFlightCount }}个低价行程，更多低价加载中..
								</span>
								<span class="fr ft16" v-else>
									共
									<searchresultcount>{{ searchFlightCount }}</searchresultcount>
									个航班
								</span>
							</div>
						</el-row>

						<el-row class="pd20 fliter-box" type="flex" justify="space-between"
							v-if="FilterFlightInfo!=null" style="border: none;">
							<div>
								<span class="ft16 mr10">筛选</span>
								<div class="filter-button">
									<el-dropdown :hide-on-click="false" trigger="click" hide-timeout="3000"
										@visible-change="clickChange" ref="messageDrop">
										<span class="el-dropdown-link">
											航空公司
											<i class="el-icon-arrow-down" v-if="messageDrop==false"></i>
											<i class="el-icon-arrow-up" v-if="messageDrop==true"></i>
										</span>
										<el-dropdown-menu slot="dropdown" class="drop-item company-item"
											v-if="FilterFlightInfo.filterAirines != null">
											<div @click="hideChange()" class="flex-between  pl10 pr10 title-head">
												<strong class="ft16">航空公司</strong>
												<span class="iconfont icon-chalv-guanbi"></span>
											</div>
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterAirines"
												:key="'filterAirines'+index" :label="item.filterInfo.Name"
												:value="item.filterInfo.Code" :command="item.filterInfo.Code">
												<el-checkbox-group v-model="checkfilterAirines" @change="bindSetFilter">
													<el-checkbox :label="item.filterInfo.Code">
														<div class="flexbox flex-between w200">
															<div>
																<span class="mr10">
																	<!-- {{item.filterInfo.IsChecked}} -->
																	<img :src="item.filterInfo.imgurl" class="img"
																		style="width: 20px;position:relative;top:4px;" />
																	<span class="ml10">{{ item.filterInfo.Name }}</span>
																</span>
															</div>
															<div class="text-yuan">
																<small class="text-muted"
																	v-if="item.filterInfo.price != ''">￥
																	<strong>{{ item.filterInfo.price }}</strong>
																</small>
															</div>
														</div>
													</el-checkbox>
												</el-checkbox-group>
											</el-dropdown-item>


										</el-dropdown-menu>
									</el-dropdown>
								</div>
								<div class="filter-button">
									<el-dropdown :hide-on-click="false" trigger="click" hide-timeout="3000"
										@visible-change="clickChange2" ref="messageDrop2">
										<span class="el-dropdown-link">
											起抵时间
											<i class="el-icon-arrow-down" v-if="messageDrop2==false"></i>
											<i class="el-icon-arrow-up" v-if="messageDrop2==true"></i>
										</span>

										<el-dropdown-menu slot="dropdown" class="drop-item company-item"
											v-if="FilterFlightInfo.filterDeparturedates != null">
											<div class="flex-between">
												<div class="drop-item2 ">
													<div class="pl20 pr10 mb10"><strong class="ft16">起飞时段</strong></div>

													<el-dropdown-item class="drop-item"
														v-for="(item, index) in FilterFlightInfo.filterDeparturedates"
														:key="'filterDeparturedates'+index"
														:label="item.filterInfo.Name" :value="item.filterInfo.Code"
														:command="item.filterInfo.Code">

														<div class="flexbox flex-between  w200">
															<div class="flexbox flex-between">
																<el-checkbox-group v-model="checkfilterDeparturedates"
																	@change="bindSetFilter">
																	<el-checkbox :label="item.filterInfo.Code">
																		<div>
																			<span
																				class="mr10">{{ item.filterInfo.Name }}</span>
																		</div>
																		<div class="text-yuan">
																			<small class="text-muted"
																				v-if="item.filterInfo.price != ''">￥
																				<strong>{{ item.filterInfo.price }}</strong>
																			</small>
																		</div>
																	</el-checkbox>
																</el-checkbox-group>
															</div>

														</div>

													</el-dropdown-item>
												</div>

												<div class="drop-item"
													v-if="FilterFlightInfo.filteArrivaldates != null">
													<div class="pl20 pr10 mb10 flex-between">
														<strong class="ft16">抵达时段</strong>
														<span class="iconfont icon-chalv-guanbi"
															@click="hideChange2()"></span>
													</div>

													<el-dropdown-item class="drop-item"
														v-for="(item, index) in FilterFlightInfo.filteArrivaldates"
														:key="'filteArrivaldates'+index" :label="item.filterInfo.Name"
														:value="item.filterInfo.Code" :command="item.filterInfo.Code">

														<div class="flexbox flex-between">
															<el-checkbox-group v-model="checkfilteArrivaldates"
																@change="bindSetFilter">
																<el-checkbox :label="item.filterInfo.Code">
																	<div class="flex-between  w200">
																		<div>
																			<span
																				class="mr10">{{ item.filterInfo.Name }}</span>

																		</div>
																		<div class="text-yuan">
																			<small class="text-muted"
																				v-if="item.filterInfo.price != ''">￥
																				<strong>{{ item.filterInfo.price }}</strong>
																			</small>
																		</div>
																	</div>
																</el-checkbox>
															</el-checkbox-group>
														</div>

													</el-dropdown-item>

												</div>
											</div>
										</el-dropdown-menu>


									</el-dropdown>
								</div>
								<!-- <div class="filter-button">
									<el-dropdown :hide-on-click="false">
										<span class="el-dropdown-link">
											抵达时间段
											<i class="el-icon-arrow-down el-icon--right"></i>
										</span>
										<el-dropdown-menu slot="dropdown" class="drop-item"
											v-if="FilterFlightInfo.filteArrivaldates != null">
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filteArrivaldates" :key="index"
												:label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code"
												@click.native="filterItemClick(index, 'Arrivaldates')">
												<div class="flexbox flex-between">
													<div>
														<span class="mr10">{{ item.filterInfo.Name }}</span>
														<small class="text-muted"
															v-if="item.filterInfo.price != ''">￥{{ item.filterInfo.price }}</small>
													</div>
													<span v-if="item.filterInfo.IsChecked"
														class="iconfont icon-wancheng ft14"></span>
												</div>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div> -->

								<div class="filter-button" v-if="FilterFlightInfo.filterDepartureAirports != null">
									<el-dropdown :hide-on-click="false" trigger="click" hide-timeout="3000"
										@visible-change="clickChange3" ref="messageDrop3">
										<span class="el-dropdown-link">
											机场
											<i class="el-icon-arrow-down" v-if="messageDrop3==false"></i>
											<i class="el-icon-arrow-up" v-if="messageDrop3==true"></i>
										</span>

										<el-dropdown-menu slot="dropdown" class="drop-item">
											<div class="flex-between" style="align-items: flex-start;">
												<div class="drop-item2 ">
													<div class="pl20 pr10 mb10"><strong class="ft16">出发机场</strong></div>
													<el-dropdown-item class="drop-item company-item"
														v-for="(item, index) in FilterFlightInfo.filterDepartureAirports"
														:key="'filterDepartureAirports'+index"
														:label="item.filterInfo.Name" :value="item.filterInfo.Code"
														:command="item.filterInfo.Code">

														<div class="flexbox flex-between  w200">
															<el-checkbox-group v-model="checkfilteDepartureAirports"
																@change="bindSetFilter">
																<el-checkbox :label="item.filterInfo.Code">
																	<div class="flexbox flex-between  w200">
																		<div>
																			<span
																				class="mr10">{{ item.filterInfo.Name }}</span>

																		</div>
																		<div class="text-yuan">
																			<small class="text-muted"
																				v-if="item.filterInfo.price != ''">￥
																				<strong>{{ item.filterInfo.price }}</strong>
																			</small>
																		</div>
																	</div>
																</el-checkbox>
															</el-checkbox-group>
														</div>
													</el-dropdown-item>
												</div>

												<div class="drop-item company-item">
													<div class="pl20 pr10 mb10 w200 flex-between">
														<strong class="ft16">抵达机场</strong>
														<span class="iconfont icon-chalv-guanbi"
															@click="hideChange3()"></span>
													</div>
													<el-dropdown-item class="drop-item company-item"
														v-for="(item, index) in FilterFlightInfo.filterArriveAirports"
														:key="'filterArriveAirports'+index"
														:label="item.filterInfo.Name" :value="item.filterInfo.Code"
														:command="item.filterInfo.Code">
														<div class="flexbox flex-between">
															<el-checkbox-group v-model="checkfilteArriveAirports"
																@change="bindSetFilter">
																<el-checkbox :label="item.filterInfo.Code">
																	<div class="flexbox flex-between w200">
																		<div>
																			<span
																				class="mr10">{{ item.filterInfo.Name }}</span>
																		</div>
																		<div class="text-yuan">
																			<small class="text-muted"
																				v-if="item.filterInfo.price != ''">￥
																				<strong>{{ item.filterInfo.price }}</strong>
																			</small>
																		</div>
																	</div>
																</el-checkbox>
															</el-checkbox-group>
														</div>
													</el-dropdown-item>
												</div>
											</div>
										</el-dropdown-menu>
									</el-dropdown>
								</div>
								<!-- <div class="filter-button">
									<el-dropdown :hide-on-click="false"
										v-if="FilterFlightInfo.filterArriveAirports != null">
										<span class="el-dropdown-link">
											抵达机场
											<i class="el-icon-arrow-down el-icon--right"></i>
										</span>
										<el-dropdown-menu slot="dropdown" class="drop-item">
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterArriveAirports"
												:key="index" :label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code"
												@click.native="filterItemClick(index, 'ArriveAirports')">
												<div class="flexbox flex-between">
													<div>
														<span class="mr10">{{ item.filterInfo.Name }}</span>
														<small class="text-muted"
															v-if="item.filterInfo.price != ''">￥{{ item.filterInfo.price }}</small>
													</div>
													<div></div>
													
												</div>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div> -->

								<!-- <div class="filter-button">
									<el-dropdown :hide-on-click="false" trigger="click" hide-timeout="3000"
										@visible-change="clickChange4" ref="messageDrop4"> <span
											class="el-dropdown-link">
											舱位
											<i class="el-icon-arrow-down" v-if="messageDrop4==false"></i>
											<i class="el-icon-arrow-up" v-if="messageDrop4==true"></i>
										</span>
										<el-dropdown-menu slot="dropdown" class="drop-item company-item"
											v-if="FilterFlightInfo.filterAirines != null">

											<div @click="hideChange4()" class="flex-between  pl10 pr10 title-head">
												<strong class="ft16">舱位</strong>
												<span class="iconfont icon-chalv-guanbi"></span>
											</div>
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterAirines" :key="index"
												:label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code">

												<el-checkbox v-model="item.filterInfo.IsChecked">
													<div class="flexbox flex-between w200"
														@click.native="filterItemClick(index, 'Airines')">
														<div>
															<span class="mr10">
																<span class="ml10">{{ item.filterInfo.Name }}</span>
															</span>
														</div>
														<div class="text-yuan">
															<small class="text-muted"
																v-if="item.filterInfo.price != ''">￥
																<strong>{{ item.filterInfo.price }}</strong>
															</small>
														</div>
													</div>
												</el-checkbox>
											</el-dropdown-item>


										</el-dropdown-menu>
									</el-dropdown>
								</div> -->

								<!-- <div class="filter-button">
									<el-dropdown :hide-on-click="false" trigger="click" hide-timeout="3000"
										@visible-change="clickChange5" ref="messageDrop5"> <span
											class="el-dropdown-link">
											更多
											<i class="el-icon-arrow-down" v-if="messageDrop5==false"></i>
											<i class="el-icon-arrow-up" v-if="messageDrop5==true"></i>
										</span>
										<el-dropdown-menu slot="dropdown" class="drop-item company-item"
											v-if="FilterFlightInfo.filterAirines != null">

											<div @click="hideChange5()" class="flex-between  pl10 pr10 title-head">
												<strong class="ft16">机型</strong>
												<span class="iconfont icon-chalv-guanbi"></span>
											</div>
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterAirines" :key="index"
												:label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code">

												<el-checkbox v-model="item.filterInfo.IsChecked">
													<div class="flexbox flex-between w200"
														@click.native="filterItemClick(index, 'Airines')">
														<div>
															<span class="mr10">
																<span class="ml10">{{ item.filterInfo.Name }}</span>
															</span>
														</div>
														<div class="text-yuan">
															<small class="text-muted"
																v-if="item.filterInfo.price != ''">￥
																<strong>{{ item.filterInfo.price }}</strong>
															</small>
														</div>
													</div>
												</el-checkbox>
											</el-dropdown-item>
											<div class="flex-between  pl10 pr10 title-head pt10">
												<strong class="ft16">报销凭证</strong>
											</div>
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterAirines" :key="index"
												:label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code">

												<el-checkbox v-model="item.filterInfo.IsChecked">
													<div class="flexbox flex-between w200"
														@click.native="filterItemClick(index, 'Airines')">
														<div>
															<span class="mr10">
																<span class="ml10">{{ item.filterInfo.Name }}</span>
															</span>
														</div>
														<div class="text-yuan">
															<small class="text-muted"
																v-if="item.filterInfo.price != ''">￥
																<strong>{{ item.filterInfo.price }}</strong>
															</small>
														</div>
													</div>
												</el-checkbox>
											</el-dropdown-item>
											<div class="flex-between  pl10 pr10 title-head pt10">
												<strong class="ft16">其他</strong>
											</div>
											<el-dropdown-item class="drop-item"
												v-for="(item, index) in FilterFlightInfo.filterAirines" :key="index"
												:label="item.filterInfo.Name" :value="item.filterInfo.Code"
												:command="item.filterInfo.Code">

												<el-checkbox v-model="item.filterInfo.IsChecked">
													<div class="flexbox flex-between w200"
														@click.native="filterItemClick(index, 'Airines')">
														<div>
															<span class="mr10">
																<span class="ml10">{{ item.filterInfo.Name }}</span>
															</span>
														</div>
														<div class="text-yuan">
															<small class="text-muted"
																v-if="item.filterInfo.price != ''">￥
																<strong>{{ item.filterInfo.price }}</strong>
															</small>
														</div>
													</div>
												</el-checkbox>
											</el-dropdown-item>
										</el-dropdown-menu>
									</el-dropdown>
								</div> -->

								<span class="empty-item" @click="bindClearFilter">清空</span>
							</div>

							<el-row class="tap-box" type="flex">
								<div class="sort-type" @click="bindSorter('AdultPrice')">
									<span class="active">价格</span>
									<block v-if="FilterFlightInfo.Sorter.Sortcol=='AdultPrice'">
										<span v-if="FilterFlightInfo.Sorter.Ascending"
											class="iconfont icon-iconpaixujiantoujiangxu icon-arrow icon-arrow-active"></span>
										<span v-else
											class="iconfont icon-iconpaixujiantoushengxu icon-arrow-active"></span>
									</block>
								</div>
								<div class="sort-type" @click="bindSorter('DepartureDate')">
									<span class="">时间</span>
									<block v-if="FilterFlightInfo.Sorter.Sortcol=='DepartureDate'">
										<span v-if="FilterFlightInfo.Sorter.Ascending"
											class="iconfont icon-iconpaixujiantoujiangxu icon-arrow-active"></span>
										<span v-else
											class="iconfont icon-iconpaixujiantoushengxu icon-arrow-active"></span>
									</block>
								</div>
								<div class="taxbox ml15 clearfix" v-show="false">
									<!--当前的添加class"current"-->
									<a>不含税</a>
									<a class="current">含税</a>
								</div>
							</el-row>
						</el-row>
					</div>
				</div>
			</el-row>

			<!--航班列表-->
			<div v-for="(item, index) in FlightRouting" :key="index">
				<el-row type="flex" justify="space-between" align="middle" class="flight-i" style="padding:20px;">
					<div class="airbox">
						<div class="air">
							<el-image :src="item.AirlineImg"></el-image>
							{{ item.MarktingAirlineName }}
						</div>
						<div>航班号：{{ item.trips[0].FlightNO }}</div>
						<div>餐食：{{ item.trips[0].MealText }}</div>
						<div>机型：{{ item.trips[0].AirplaneKind }}</div>
					</div>
					<el-row type="flex">
						<div class="timebox tr">
							<div class="time" style="text-align:right;">{{ item.trips[0].DepartureTime }}</div>
							<div class="airport">{{ item.trips[0].DepartureName }}{{ item.trips[0].DepartureTerminal }}
							</div>
						</div>
						<div class="translatebox">
							<i class="change-num" v-if="item.trips[0].StopAirport != ''">经停</i>
							<i class="change-num" v-else-if="item.trips[0].Stop != ''&&item.trips[0].Stop != '0'">经停{{item.trips[0].Stop}}次</i>
							<i class="change-num" v-else-if="item.TurnaroundCount != ''">转机{{item.TurnaroundCount}}次</i>
							<i class="change-num" v-else>直飞</i>
							<em class="change-sym" style="width: 120px;"></em>
							<i class="change-city"
								v-if="item.trips[0].StopAirport != ''">{{ item.trips[0].StopAirportName }}</i>
							<i class="change-city" v-else-if="item.TurnaroundCity != ''">{{ item.TurnaroundCity }}</i>
						</div>
						<div class="timebox">
							<div class="time">{{ item.trips[item.trips.length - 1].ArriveTime }}</div>
							<div class="airport">
								{{ item.trips[item.trips.length - 1].ArriveName }}{{ item.trips[item.trips.length - 1].ArrivalTerminal }}
							</div>
						</div>
					</el-row>
					<div class="durationbox">
						<span class="gray6">总时长:</span>
						{{ item.trips[0].Lishi }}
					</div>
					<div class="pricebox" style="text-align:center;">
						<span class="price ft20">￥{{ item.AdultPrice }}</span>
						<div>
							<span class="tax ft14 gray9">机建+燃油费￥{{ item.AdultTax }}</span>
						</div>
						<div class="red ft14" v-if="item.CPrice != ''">商务特惠：¥{{ item.CPrice }}</div>
						<div class="red ft14" v-if="item.FPrice != ''">头等特惠：¥{{ item.FPrice }}</div>
					</div>
					<div class="btnbox">
						<el-button type="danger" @click="bindItemClick" :data-id="item.ID">
							选择
							<span class="iconfont icon-xiala" v-if="item.ID != currentItem"></span>
							<span class="iconfont icon-xiala-copy" v-else></span>
						</el-button>
					</div>
				</el-row>
				<div class="item-detail" v-if="item.ID == currentItem">
					<el-row style="margin-bottom: 10px;" type="flex" :gutter="24" align="middle"
						v-for="(item_trip, index2) in item.BerthList" :key="index2">
						<el-col :span="4" style="text-align: center;">
							<span>{{ item_trip.BillBerthDescription }}</span>
						</el-col>
						<el-col :span="6" style="text-align: center;">
							<span>{{ item_trip.discount }}</span>
						</el-col>
						<el-col :span="6" style="text-align: center;">
							<el-row type="flex">
								<!-- <el-dropdown class="mr10"> -->
								<el-link type="danger" underline="true" class="gaiqian-refund"
									@click="bindShowTGQ(item.MarktingAirlineName,item.trips[0].FlightNO,item_trip)">退改签
								</el-link>
								<!-- <el-dropdown-menu slot="dropdown" style="width:220px">
										<el-dropdown-item>黄金糕</el-dropdown-item>
									</el-dropdown-menu> -->
								<!-- </el-dropdown> -->
								<!-- <el-dropdown class="mr10">
									<el-link type="danger" class="gaiqian-refund">行李托运</el-link>
									<el-dropdown-menu slot="dropdown" style="width:220px">
										<el-dropdown-item>黄金黄金糕黄金糕黄金糕黄金糕糕</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown> -->
							</el-row>
						</el-col>
						<el-col :span="4" style="text-align: center;">
							<div>
								<span class="price">
									<em class="ft12">¥</em>
									{{ item_trip.Price }}
								</span>
							</div>
						</el-col>
						<el-col :span="4" style="text-align: center;">
							<el-button type="danger" @click="bindItemNext" :data-id="item_trip.id">选择</el-button>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="mt20 pb20 text-c" v-if="FlightRouting.length > 0">
				<pagination @handleCurrentChange="handleCurrentChange" :pageTotal="pageCount" :page-size="pageSize">
				</pagination>
			</div>
			<el-row type="flex" justify="center">
				<FlightNoData v-if="isNoData"></FlightNoData>
				<FlightLoading type="航班" v-if="isShowFlightLoading" v-show="false"></FlightLoading>
			</el-row>
		</div>
		<el-dialog class="des-modal" :visible.sync="showGongGao" :width="widthNum" show-close="true" title="紧急公告" left>
			<div>
				<div style="height:400px;overflow-y: auto;" v-if="GetGongGaoInfo!=null">
					<div v-html="GetGongGaoInfo.Info_Content"></div>
				</div>

				<div class="text-c" style="padding: 20px 0px 10px 0px;">
					<!-- <el-button style="width: 120px;" @click="clickDel" v-if="delShow == true">取消</el-button> -->
					<el-button type="danger" class="button mt25" style="width: 120px;" @click="showGongGao=false">确定
					</el-button>
				</div>
			</div>
		</el-dialog>

		<!--退改签-->
		<el-dialog :title="tgqTitle" :visible.sync="showTGQ" width="55%">
			<el-row type="flex" class="flight-hearder" justify="space-between">
				<span></span>
				<!-- <span class="all-checked">全选</span> -->
			</el-row>

			<el-row type="flex" align="middle" class="city-boxone">
				<div class=" cabin-rule" v-if="LimitConditionInfo!=null">
					<table class="table">
						<tr>
							<td class="td-01" rowspan="3">成人票</td>
							<td colspan="2">
								<div class="feebox">
									<div class="fee-list-first"></div>
									<div class="fee-list"
										v-for="(item_fare, index3) in LimitConditionInfo.changesDetails" :key="index3">
										<div class="fee text-c">{{ item_fare.Content }}</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td class="td-02">同舱改期费</td>
							<td class="td-03">
								<div class="feebox">
									<div class="fee-list"
										v-for="(item_fare, index3) in LimitConditionInfo.changesDetails" :key="index3">
										<div class="fee">
											<div>
												¥
												<span class="price red">{{ item_fare.AdultFee }}</span>
												/人
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
						<tr>
							<td class="td-02">退票费用</td>
							<td class="td-03">
								<div class="feebox">
									<div class="fee-list"
										v-for="(item_fare, index3) in LimitConditionInfo.refundDetails" :key="index3">
										<div class="fee">
											<div>
												¥
												<span class="price red">{{ item_fare.AdultFee }}</span>
												/人
											</div>
										</div>
									</div>
								</div>
							</td>
						</tr>
					</table>

					<div class="cabin-list" v-if="LimitConditionInfo.changes!=null">
						<div class="tit">改签</div>
						<div class="text">{{ LimitConditionInfo.changes }}</div>
					</div>
					<div class="cabin-list" v-if="LimitConditionInfo.refund!=null">
						<div class="tit">退票</div>
						<div class="text">{{ LimitConditionInfo.refund }}</div>
					</div>
					<div class="cabin-list" v-if="LimitConditionInfo.baggage!=null">
						<div class="tit">行李</div>
						<div class="text">{{ LimitConditionInfo.baggage }}</div>
					</div>
					<div class="cabin-list">
						<div class="tit">备注</div>
						<div class="text">{{ LimitConditionInfo.other }}</div>
					</div>
				</div>
			</el-row>

			<!-- <div class="warn-tip">温馨提示:航班机票退票成功后，对应的保险产品会一起退，如果保险已经生效，则不能再退了！</div> -->
			<div class="text-c" style="padding-top: 40px;">
				<span slot="footer" class="dialog-footer">
					<el-button @click="showTGQ = false">确 定</el-button>
				</span>
			</div>
		</el-dialog>

		<!--提示-->
		<el-dialog class="des-modal" :visible.sync="showYanJiaLoading" :width="widthNum" show-close="true" title="提示"
			left>
			<div class="text-c">
				<img src="../../assets/serch_loading.gif" class="img" style="width: 60px;position:relative;top:4px;" />
				<p class="ft16 fn mt20 pb20">正在验证机票，此过程需要几秒钟时间...</p>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	import desAirSearch from './components/desFlifghtSearch.vue';
	import intlAirFliter from './components/desMiddleSearch.vue';
	import desModal from '../../components/desModal.vue';
	import FlightNoData from '../../components/flightNoData.vue';
	import FlightLoading from '../../components/flightLoading.vue';
	import pagination from '../../components/pagination.vue';

	import {
		duals,
		canbins,
		adults,
		childrens,
		TicketSearchInfo
	} from '@/data/staticData';

	var datehelper = require('@/utils/datehelper.js');
	var util = require('@/utils/util.js');
	var yqfCommon = require('@/utils/yqfCommon.js');

	var localdatas = require('@/utils/localdatas.js');
	//接口
	var flightApi = require('@/api/flightApi.js');
	var yqfApi = require('@/api/yqfApi.js');

	var that;
	var index = 0;
	var jobid = '';
	export default {
		components: {
			desAirSearch,
			// intlAirFliter,
			// desModal,
			FlightNoData,
			FlightLoading,
			pagination
		},
		data() {
			return {
				Duals: duals,
				canbins: canbins,
				adults: adults,
				childrens: childrens,

				showTGQ: false,

				showCity: false,
				showDate: false,
				cityIdx: 0, //下标
				cityType: 0, //类型
				dateIdx: 0, //日期组件下标
				selectDate: '', //选择的日期

				TicketSearchInfo: TicketSearchInfo,
				BookFlightRouting: [], //预订的航班列表
				FlightRouting: [],
				FilterFlightInfo: null,
				SortType: 1,
				searchFlightCount: 0,
				pageCount: 0,
				isNoData: false,
				selectFlightID: '',
				isShowFlightLoading: true,
				isDoneData: false,
				SegRef: 1,
				SegRefCount: 1,
				pageSize: 20,
				pageindex: 0,
				filterObj: {
					airlineStr: '',
					departureDateStr: '',
					arrivalDateStr: '',
					turnaroundCountStr: '',
					isZhifei: true
				},
				FilterCanbinInfoIdx: [],
				FilterFlightInfoIdx: [],
				filterDeparturedatesIdx: [],
				filteArrivaldatesIdx: [],
				filterDepartureAirportsIdx: [],
				filterArriveAirportsIdx: [],
				// FilterFlightInfoIdx:[],
				taxchecked: true,
				currentItem: '',
				currentMoreItem: '',
				IsChinaCity: true,
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				searchDates: '',
				initial_index: 0,
				LimitConditionInfo: null,
				tgqTitle: '',
				showGongGao: false,
				GetGongGaoInfo: null, //公告内容
				showYanJiaLoading: false,
				messageDrop: false,
				messageDrop2: false,
				messageDrop3: false,
				messageDrop4: false,
				messageDrop5: false,
				widthNum: '100',
				checkfilterAirines: [],
				checkfilterDeparturedates: [],
				checkfilteArrivaldates: [],
				checkfilteDepartureAirports: [],
				checkfilteArriveAirports: [],
				checkfilterTransferCitys: [],
				searchCount: 0
			};
		},
		created() {
			that = this;
			var options = that.$route.query;
			console.log(options);

			if (!yqfCommon.isNullOrEmpty(options.m_begionCity) && !yqfCommon.isNullOrEmpty(options.m_endCity)) {
				let parm = {
					dual: options.dual,
					m_begionCity: options.m_begionCity,
					m_endCity: options.m_endCity,
					m_startdate: options.m_startdate,
					airline: options.airline,
					cabin: options.cabin,
					sorttype: options.sorttype
				};
				flightApi.GetTicketSearchByUrlParm(parm, function(result) {
					// console.log(result);;
					if (result.code == 0) {
						var TicketSearchInfo = result.data.TicketSearchInfo;
						console.log(TicketSearchInfo);
						localdatas.setTicketSearchInfo(TicketSearchInfo);
						that.TicketSearchInfo = TicketSearchInfo;

						if (TicketSearchInfo.IsChinaCity) {
							// util.redirectTo('/pages/Ticekets/showDomesitcflight/showDomesitcflight');
							// if (that.SegRef == 1) {
							// 	that.inDate = that.ticketSearchInfo.StartDate;
							// } else {
							// 	that.inDate = that.ticketSearchInfo.ReturnDate;
							// }
							getSearchFSJobid();
						} else {
							that.$router.push({
								path: '/Flight/showIntlflight'
							});
						}
					} else {
						that.$router.push({
							path: '/Flight'
						});
					}
				});
			} else {
				that.TicketSearchInfo = localdatas.getTicketSearchInfo();
				if (!that.TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/showIntlflight'
					});
				}
				console.log(that.TicketSearchInfo);
				getSearchFSJobid();
			}

			$(window).scrollTop("0");
		},
		methods: {
			hideChange() {
				this.$refs.messageDrop.hide();
			},

			hideChange2() {
				this.$refs.messageDrop2.hide();
			},
			hideChange3() {
				this.$refs.messageDrop3.hide();
			},
			hideChange4() {
				this.$refs.messageDrop4.hide();
			},
			hideChange5() {
				this.$refs.messageDrop5.hide();
			},
			clickChange(bool) {
				console.log(bool);
				this.messageDrop = bool;
			},
			clickChange2(bool) {
				console.log(bool);
				this.messageDrop2 = bool;
			},
			clickChange3(bool) {
				console.log(bool);
				this.messageDrop3 = bool;
			},
			clickChange4(bool) {
				console.log(bool);
				this.messageDrop4 = bool;
			},
			clickChange5(bool) {
				console.log(bool);
				this.messageDrop5 = bool;
			},
			bindTGChage(item) {
				console.log(item);
			},
			handleScroll() {
				// if (that.TicketSearchInfo.Dual == 2) {
				// 	var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
				// } else {
				// 	var rect = document.querySelector('#scrollCity').getBoundingClientRect();
				// }
				// this.y = rect.top;
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			searchInput(e) {
				console.log(e);
				this.$refs.cityAssembly.searchInput(e);
			},
			bindReSearch(e) {
				reSearch();
			},
			//清空筛选数据
			bindClearFilter(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				// that.FilterFlightInfoIdx = [];
				// that.filterDeparturedatesIdx = [];
				// that.filteArrivaldatesIdx = [];
				// that.filterDepartureAirportsIdx = [];
				// that.filterArriveAirportsIdx = [];
				reSetFilter();
				getFlightList();
			},
			bindItemClick(e) {
				var thisitem = e.currentTarget.dataset.id;
				if (that.currentItem != '' && that.currentItem == thisitem) {
					thisitem = '';
				}
				that.currentItem = thisitem;
				// document.querySelector('#flight_' + thisitem + '').scrollIntoView(true);
			},
			bindMoreItemClick(e) {
				var thisitem = e.currentTarget.dataset.id;
				if (that.currentMoreItem != '' && that.currentMoreItem == thisitem) {
					thisitem = '';
				}
				that.currentMoreItem = thisitem;
				document.querySelector('#flight_' + thisitem + '').scrollIntoView(true);
			},
			//排序
			bindSort(Sortcol) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				that.FilterFlightInfo.Sorter.Sortcol = Sortcol;
				that.FilterFlightInfo.Sorter.Ascending = !that.FilterFlightInfo.Sorter.Ascending;
				getFlightList();
			},
			bindFilterChange(e) {
				clearFilter();

				console.log(that.FilterFlightInfo);
				//航司
				let FilterFlightInfoIdx = that.FilterFlightInfoIdx;
				for (var idx = 0; idx < FilterFlightInfoIdx.length; idx++) {
					that.FilterFlightInfo.filterAirines[FilterFlightInfoIdx[idx]].filterInfo.IsChecked = true;
				}
				//出发时间
				let filterDeparturedatesIdx = that.filterDeparturedatesIdx;
				console.log(that.FilterFlightInfo.filterDeparturedates);
				for (var idx = 0; idx < filterDeparturedatesIdx.length; idx++) {
					that.FilterFlightInfo.filterDeparturedates[filterDeparturedatesIdx[idx]].filterInfo.IsChecked = true;
				}
				//抵达时间
				let filteArrivaldatesIdx = that.filteArrivaldatesIdx;
				for (var idx = 0; idx < filteArrivaldatesIdx.length; idx++) {
					that.FilterFlightInfo.filteArrivaldates[filteArrivaldatesIdx[idx]].filterInfo.IsChecked = true;
				}

				//出发地
				let filterDepartureAirportsIdx = that.filterDepartureAirportsIdx;
				for (var idx = 0; idx < filterDepartureAirportsIdx.length; idx++) {
					that.FilterFlightInfo.filterDepartureAirports[filterDepartureAirportsIdx[idx]].filterInfo.IsChecked =
						true;
				}
				//目的地
				let filterArriveAirportsIdx = that.filterArriveAirportsIdx;
				for (var idx = 0; idx < filterArriveAirportsIdx.length; idx++) {
					that.FilterFlightInfo.filterArriveAirports[filterArriveAirportsIdx[idx]].filterInfo.IsChecked = true;
				}
				getFlightList();
			},

			//筛选
			filterItemClick(idx, typestr) {
				// that.$alert(idx);
				console.log(idx, typestr);
				switch (typestr) {
					case 'Airines':
						this.FilterFlightInfo.filterAirines[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filterAirines[idx].filterInfo.IsChecked;
						break;
					case 'Departuredates':
						this.FilterFlightInfo.filterDeparturedates[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filterDeparturedates[idx].filterInfo.IsChecked;
						break;
					case 'Arrivaldates':
						this.FilterFlightInfo.filteArrivaldates[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filteArrivaldates[idx].filterInfo.IsChecked;
						break;
					case 'TransferCitys':
						this.FilterFlightInfo.filterTransferCitys[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filterTransferCitys[idx].filterInfo.IsChecked;
						break;
					case 'DepartureAirports':
						this.FilterFlightInfo.filterDepartureAirports[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filterDepartureAirports[idx].filterInfo.IsChecked;
						break;
					case 'ArriveAirports':
						this.FilterFlightInfo.filterArriveAirports[idx].filterInfo.IsChecked = !this.FilterFlightInfo
							.filterArriveAirports[idx].filterInfo.IsChecked;
						break;
				}
				// this.$emit('bindChageFilter');
				getFlightList();
			},
			//排序
			bindSorter: function(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				console.log(e);
				that.FilterFlightInfo.Sorter.Sortcol = e;
				that.FilterFlightInfo.Sorter.Ascending = !that.FilterFlightInfo.Sorter.Ascending;

				if (e == 'DepartureDate') {
					that.filterIndex = 1;
				} else if (e == 'AdultPrice') {
					that.filterIndex = 2;
				} else if (e == 'TurnaroundCount') {
					that.filterIndex = 3;
					that.FilterFlightInfo.Sorter.Ascending = true;
				}

				getFlightList();
			},
			//筛选
			bindSetFilter: function(e) {
				if (that.FilterFlightInfo == null) {
					return;
				}
				console.log(e);
				setFilter();
			},
			//分页
			handleCurrentChange(page) {
				console.log(`当前页: ${page}`);
				that.pageindex = page - 1;
				getFlightList();
			},
			//退改签
			bindShowTGQ(MarktingAirlineName, FlightNO, item) {
				console.log(item);
				
				that.tgqTitle = MarktingAirlineName + " " + FlightNO + " " + item.BillBerthDescription + " " + item
					.Berth; //"南航CZ6748 商务舱 I";

				flightApi.GetDomesticAirLimitConditionInfo({
						AdultPrice: item.Price,
						ChildPrice: item.ChildPrice,
						Airline: item.Airline,
						DepartureDate: item.DepartureDate,
						Berth: item.Berth,
						id: item.id,
					},
					function(result) {
						// console.log(result);;
						if (result.code == 0) {
							that.showTGQ = true;
							that.LimitConditionInfo = result.data.limitConditionInfo;
						} else {
							that.showTGQ = false;
							that.$alert(result.msg);
						}
					}
				);

			},

			//重新选择航班
			bindRemoveBookFlight(e) {
				reSearch();
			},
			//搜索bindReSearch
			bindSearchFlight(TicketSearchInfo) {
				if (!TicketSearchInfo.IsChinaCity) {
					that.$router.push({
						path: '/Flight/showIntlflight'
					});
				} else {
					that.TicketSearchInfo = TicketSearchInfo;
					reSearch();
				}
			},
			//选择航班
			bindItemNext: function(e) {
				console.log(that.isDoneData);
				var id = e.currentTarget.dataset.id;
				if (!that.isDoneData) {
					// util.wxalert('数据未加载完，请加载完后再操作');
					that.$message.error('数据未加载完，请加载完后再操作');
					return;
				}
				console.log(id);
				// return;
				// var id = id; //console.log(id);
				// uni.showLoading({
				// 	title: '加载中'
				// });
				that.showYanJiaLoading = true;
				var segref = that.SegRef;
				var segRefCount = that.SegRefCount;
				flightApi.BookFlight({
						id: id,
						segref: segref
					},
					function(result) {
						that.showYanJiaLoading = false;
						if (result.code == 1) {

							that.$router.push({
								path: '/Flight/Confirm',
								query: {
									id: id
								}
							});
							// uni.navigateTo({
							// 	url: '/pages/flight/flightconfirm/flightconfirm'
							// });
						} else if (result.code == -1) {
							that.$confirm('由于您太久操作，信息已过期，是否重新查询？', '确认信息', {
									distinguishCancelAndClose: true,
									confirmButtonText: '是',
									cancelButtonText: '放弃'
								})
								.then(() => {
									reSearch();
								})
								.catch(action => {});
						} else {
							// console.log(result);;
							that.selectFlightID = id;
							var BookFlightRouting = JSON.parse(result.data.BookFlightRouting);
							that.pageindex = 0;
							that.SegRef = that.SegRef + 1;
							that.BookFlightRouting = BookFlightRouting;
							that.currentItem = '';
							getSearchFSJobid();
							// getFlightList();
							// getFilterIntFlightInfo();
						}
					}
				);
			},
		}
	};

	function reSearch() {
		that.SegRef = 1;
		that.FlightRouting = [];
		that.BookFlightRouting = [];

		// that.isNodata = false;
		// that.isShowFlightLoading = true;
		// // that.TicketSearchInfo = TicketSearchInfo;

		getSearchFSJobid();
	}
	//查询机票
	function getSearchFSJobid() {
		that.searchCount = 0;
		that.isNoData = false;
		that.FlightRouting = [];
		that.isShowFlightLoading = true;

		that.FilterFlightInfoIdx = [];
		that.filterDeparturedatesIdx = [];
		that.filteArrivaldatesIdx = [];
		that.filterDepartureAirportsIdx = [];
		that.filterArriveAirportsIdx = [];
		// that.FilterFlightInfo = null;
		that.searchFlightCount = 0;
		if (that.TicketSearchInfo.IsChange) {
			getSearchByOrder();
		} else {
			flightApi.GetSearchFSJobid({
					isus: true,
					segref: that.SegRef,
					IsChinaCity: that.IsChinaCity,
					ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
				},
				function(result) {
					// console.log(result);;
					if (result.code != 0) {
						// uni.hideLoading();
						// (that.isNodata = true), (that.isShowFlightLoading = false);
						return;
					} else {
						jobid = result.data.jobid; //写入统计
						GetGongGao();
						// try {
						// 	var MQuerydate = JSON.parse(result.data.MQueryJson);
						// 	var trackPostData = {
						// 		AccessModeID: 2,
						// 		AirTicket: {
						// 			MQuery: MQuerydate
						// 		}
						// 	};
						// 	util.getYqfTrack(trackPostData);
						// } catch (e) {}

						getFlightListByJobid();
					}
				}
			);
		}
	}

	//根据JOBID获取航班列表数据
	function getFlightListByJobid() {
		console.log(jobid);
		if (jobid == '') {
			console.log('jobid为空');
			return;
		}
		// let searchCount = that.searchCount;
		that.searchCount++;
		console.log(that.searchCount);
		let parm = {
			jobid: jobid,
			searchCount: that.searchCount,
			SortType: that.SortType,
			segref: that.SegRef,
			pagesize: that.pageSize,
			ticketsearchinfo: JSON.stringify(that.TicketSearchInfo)
		};
		console.log(parm);
		//发送请求
		flightApi.GetDomesticFlightDataByJobid(parm, function(result) {
			// console.log(result);;
			let searchFlightCount = that.searchFlightCount;
			console.log(searchFlightCount);
			let isComplete = false;
			var FlightRoutingList = null;

			if (result.code == 0) {
				isComplete = result.data.isComplete;
				searchFlightCount += result.data.datacount;
				// that.isShowFlightLoading = false;
				FlightRoutingList = JSON.parse(result.data.FlightRouting);
				console.log(FlightRoutingList);
				that.pageCount = result.data.pageCount;
				if (FlightRoutingList != null && FlightRoutingList.length > 0) {
					that.FlightRouting = FlightRoutingList;
					that.LimitConditionInfo = FlightRoutingList[0].LimitConditionInfo;
					console.log(that.LimitConditionInfo);
				}

			}
			if (!isComplete) {
				setTimeout(function() {
					getFlightListByJobid(jobid);
				}, 1500);
			} else {
				that.isShowFlightLoading = false;
				if (searchFlightCount == 0) {
					that.isNoData = true;
				} else {
					getFilterIntFlightInfo();
				}
			}
			// if()

			that.isDoneData = isComplete;
			that.searchFlightCount = searchFlightCount;
		});
	}
	//获取航班列表数据
	function getFlightList() {
		let isNoData = false;
		console.log(that.pageindex);
		that.isNoMoreData = false;
		// if (that.isLoading == false) {
		// 	uni.showLoading({
		// 		title: '加载中'
		// 	});
		// }

		let parm = {
			SegRef: that.SegRef,
			SortType: that.SortType,
			pageindex: that.pageindex,
			pagesize: that.pageSize,
			// id: that.selectFlightID,
			IsChinaCity: that.IsChinaCity,
			//筛选的航司
			FilterFlightInfo: JSON.stringify(that.FilterFlightInfo)
		};
		flightApi.GetFlightList(parm, function(result) {
			if (result.code == 0) {
				that.pageCount = result.data.pageCount;
				that.isLoadDone = result.data.isDone;
				var FlightRoutingList = [];
				if (result.data.FlightRouting != null) {
					FlightRoutingList = JSON.parse(result.data.FlightRouting);
				}
				// if (pageindex > 0 && FlightRoutingList.length == 0) {
				// 	return;
				// }

				if (FlightRoutingList.length == 0) {
					isNoData = true;
				} else {
					isNoData = false;
				}
				that.FlightRouting = FlightRoutingList;

				that.isNoData = isNoData;
			} else if (result.code == -1) {
				that.$alert(result.msg, {
					confirmButtonText: '确定',
					callback: action => {
						reSearch();
					}
				});
			} else {
				console.log(result.msg);
				that.isNoData = true;
				that.FlightRouting = [];
			}
			that.isLoading = false;
		});
	}
	//删除预订的航班信息
	function getRemoveBookFlight() {
		flightApi.RemoveBookFlight(null, function(result) {
			clearFilter();
			reSearch();
		});
	}
	//获取筛选的数据
	function getFilterIntFlightInfo() {
		flightApi.GetFilterFlightInfo({
				SegRef: that.SegRef,
				IsChinaCity: true
			},
			function(result) {
				console.log(result.data);
				// var FilterFlightInfo = JSON.parse(result.data); //console.log(fliterAirlineInfo);
				if (result.code == 0) {
					that.FilterFlightInfo = result.data.flightFilterInfo;
					console.log(that.FilterFlightInfo.filterAirines);
					that.searchDates = result.data.searchDates;
					that.initial_index = result.data.initial_index;
				}
			}
		);
	}

	//筛选
	function setFilter() {
		that.pageindex = 0;

		//航司
		var checkfilterAirines = that.checkfilterAirines;
		var filterAirines = that.FilterFlightInfo.filterAirines;
		for (var i = 0; i < filterAirines.length; i++) {
			if (checkfilterAirines.includes(filterAirines[i].filterInfo.Code)) {
				filterAirines[i].filterInfo.IsChecked = true;
			} else {
				filterAirines[i].filterInfo.IsChecked = false;
			}
		}
		that.FilterFlightInfo.filterAirines = filterAirines;
		console.log(filterAirines);

		//出发时间
		var checkfilterDeparturedates = that.checkfilterDeparturedates;

		var filterDeparturedates = that.FilterFlightInfo.filterDeparturedates;
		// console.log(filterDeparturedates);
		for (var i = 0; i < filterDeparturedates.length; i++) {
			if (checkfilterDeparturedates.includes(filterDeparturedates[i].filterInfo.Code)) {
				filterDeparturedates[i].filterInfo.IsChecked = true;
			} else {
				filterDeparturedates[i].filterInfo.IsChecked = false;
			}
		}
		console.log(filterDeparturedates);
		that.FilterFlightInfo.filterDeparturedates = filterDeparturedates;

		//抵达时间
		var checkfilteArrivaldates = that.checkfilteArrivaldates;

		var filteArrivaldates = that.FilterFlightInfo.filteArrivaldates;

		// console.log(filterDeparturedates);
		for (var i = 0; i < filteArrivaldates.length; i++) {
			if (checkfilteArrivaldates.includes(filteArrivaldates[i].filterInfo.Code)) {
				filteArrivaldates[i].filterInfo.IsChecked = true;
			} else {
				filteArrivaldates[i].filterInfo.IsChecked = false;
			}
		}
		console.log(filteArrivaldates);
		that.FilterFlightInfo.filteArrivaldates = filteArrivaldates;

		//中转城市
		var checkfilterTransferCitys = that.checkfilterTransferCitys;
		var filterTransferCitys = that.FilterFlightInfo.filterTransferCitys;
		// console.log(filterDeparturedates);
		if (filterTransferCitys != null) {
			for (var i = 0; i < filterTransferCitys.length; i++) {
				if (checkfilterTransferCitys.includes(filterTransferCitys[i].filterInfo.Code)) {
					filterTransferCitys[i].filterInfo.IsChecked = true;
				} else {
					filterTransferCitys[i].filterInfo.IsChecked = false;
				}
			}
			console.log(filterTransferCitys);
			that.FilterFlightInfo.filterTransferCitys = filterTransferCitys;
		}

		//出发机场
		var checkfilteDepartureAirports = that.checkfilteDepartureAirports;
		var filterDepartureAirports = that.FilterFlightInfo.filterDepartureAirports;
		// console.log(filterDeparturedates);
		if (filterDepartureAirports != null) {
			for (var i = 0; i < filterDepartureAirports.length; i++) {
				if (checkfilteDepartureAirports.includes(filterDepartureAirports[i].filterInfo.Code)) {
					filterDepartureAirports[i].filterInfo.IsChecked = true;
				} else {
					filterDepartureAirports[i].filterInfo.IsChecked = false;
				}
			}
			console.log(filterDepartureAirports);
			that.FilterFlightInfo.filterDepartureAirports = filterDepartureAirports;
		}
		//抵达机场
		var checkfilteArriveAirports = that.checkfilteArriveAirports;
		var filterArriveAirports = that.FilterFlightInfo.filterArriveAirports;
		// console.log(filterDeparturedates);
		if (filterArriveAirports != null) {
			for (var i = 0; i < filterArriveAirports.length; i++) {
				if (checkfilteArriveAirports.includes(filterArriveAirports[i].filterInfo.Code)) {
					filterArriveAirports[i].filterInfo.IsChecked = true;
				} else {
					filterArriveAirports[i].filterInfo.IsChecked = false;
				}
			}
			console.log(filterArriveAirports);
			that.FilterFlightInfo.filterArriveAirports = filterArriveAirports;
		}
		getFlightList();
	}
	//重置筛选数据
	function reSetFilter() {
		that.pageindex = 0;
		// that.IsZhifei = false;
		// that.fliterselected = false;
		// that.selectAllFlag = false;

		if (that.FilterFlightInfo != null) {
			var FilterFlightInfo = that.FilterFlightInfo;

			for (var i = 0; i < FilterFlightInfo.filterAirines.length; i++) {
				FilterFlightInfo.filterAirines[i].filterInfo.IsChecked = false;
			}
			for (var i = 0; i < FilterFlightInfo.filterDeparturedates.length; i++) {
				FilterFlightInfo.filterDeparturedates[i].filterInfo.IsChecked = false;
			}
			for (var i = 0; i < FilterFlightInfo.filteArrivaldates.length; i++) {
				FilterFlightInfo.filteArrivaldates[i].filterInfo.IsChecked = false;
			}
			if (FilterFlightInfo.filterTransferCitys != null) {
				for (var i = 0; i < FilterFlightInfo.filterTransferCitys.length; i++) {
					FilterFlightInfo.filterTransferCitys[i].filterInfo.IsChecked = false;
				}
			}
			if (FilterFlightInfo.filterDepartureAirports != null) {
				for (var i = 0; i < FilterFlightInfo.filterDepartureAirports.length; i++) {
					FilterFlightInfo.filterDepartureAirports[i].filterInfo.IsChecked = false;
				}
			}
			if (FilterFlightInfo.filterArriveAirports != null) {
				for (var i = 0; i < FilterFlightInfo.filterArriveAirports.length; i++) {
					FilterFlightInfo.filterArriveAirports[i].filterInfo.IsChecked = false;
				}
			}
			that.FilterFlightInfo = FilterFlightInfo;
		}
		that.checkfilterDeparturedates = [];
		that.checkfilteArrivaldates = [];
		that.checkfilterAirines = [];
		that.checkfilterTransferCitys = [];
		that.checkfilteDepartureAirports = [];
		that.checkfilteArriveAirports = [];
	}
	//进行验价
	function getVeriflyFlight() {
		uni.showLoading({
			title: '验价中...'
		});
		flightApi.GetVeriflyFlightByBookFlight(null, function(result) {
			uni.hideLoading();
			if (result.code == 0) {
				//写入统计;
				try {
					if (!util.isNullOrEmpty(result.data.MCheckPrice)) {
						var MCheckPrice = JSON.parse(result.data.MCheckPrice);
						var trackPostData = {
							AccessModeID: 3,
							AirTicket: {
								MCheckPrice: MCheckPrice
							}
						};
						util.getYqfTrack(trackPostData);
					}
				} catch (e) {}
				util.redirectTo('/pages/flight/confirm/confirm');
			} else if (result.code == -2) {
				that.$confirm(result.msg, {
						distinguishCancelAndClose: true,
						confirmButtonText: '确认',
						cancelButtonText: '继续预订'
					})
					.then(() => {
						reSearch();
					})
					.catch(action => {
						that.$router.push({
							path: '/DomesticAir/booking',
							query: {
								id: id
							}
						});
					});
			} else {
				that.$alert(result.msg, {
					confirmButtonText: '确定',
					callback: action => {
						reSearch();
					}
				});
			}
		});
	}
	//获取公告
	function GetGongGao() {
		// yqfApi.GetGongGao({
		// 		cityCode: that.TicketSearchInfo.ArrivalCityCode,
		// 		country_Code: that.TicketSearchInfo.ArrivalCountryCode,
		// 		iswebsite: true,
		// 	},
		// 	function(result) {
		// 		// console.log(result);;
		// 		if (result.code == 0) {
		// 			that.GetGongGaoInfo = JSON.parse(result.data.AdvisorInfo);
		// 			that.showGongGao = that.GetGongGaoInfo != null;
		// 		}
		// 	}
		// );
	}
</script>

<style lang="scss">
	@import '../../style/Flight/flight.scss';
</style>

<template>
	<div class="select-flightbox">
		<div class="mt10">
			<el-row type="flex" class="block-title border-bottom">
				<span class="fb mr20">已选：第2程</span>
				<span class="ml20 mr20">广州-北京</span>
				<span class="ml20">2017.09.12 周二</span>
			</el-row>
			<el-row type="flex" justify="space-between" align="middle"  class="flight-i">
				<div class="airbox">
					<div class="air">
						<img src="http://airlineico.b0.upaiyun.com/MU.png!16x16">中国国际航空
					</div>
					<span>CZ327 380</span>
				</div>
				<el-row type="flex">
					<div class="timebox tr">
						<div class="time" style="text-align:right;">12:00</div>
						<div class="airport">广州白云国际机场</div>
					</div>
					<div class="translatebox">
						<i class="change-num">转机1次</i>
						<em class="change-sym"></em>
						<i class="change-city">武汉武汉</i>
					</div>
					<div class="timebox">
						<div class="time">12:00</div>
						<div class="airport">广州白云国际机场</div>
					</div>
				</el-row>
				<div class="durationbox">
					<span class="gray6">总时长:</span>19h30m
				</div>
				<div class="pricebox">
					<span class="price ft14">￥1030</span>
				</div>
				<div class="btnbox">
					<a class="reset-btn">[重新选择]</a>
				</div>
			</el-row>
		</div>
		<div class="flight-route">
		<div class="titlebox">
			选择第1段：
			纽约 - 北京
			10月14日 周三
			<span class="subtitle">(已查询到<span>1</span>个低价行程)</span>
			<span class="subtitle">

				(
				<img src="../../../assets/serch_loading.gif" class="img" style="width: 20px;position:relative;top:4px;">

				已查询到0个低价行程，更多低价加载中…

				)

			</span>
		</div>
		<el-row class="select-tit" type="flex" justify="space-between">
			<div>
				<span class="tit">去：广州 -- 上海</span>
				<span class="ml20 mr20 ft16">2021-07-27 周二</span>
			</div>
			<div>
				<span class="fr ft16" id="obj_showresulttotal" style="">共<searchresultcount>584</searchresultcount>
					个航班</span>
			</div>
		</el-row>
		<el-row class="pd20 fliter-box" type="flex" justify="space-between">
			<div>
				<span class="ft16 mr10">筛选</span>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							航空公司<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item">
							<el-dropdown-item class="drop-item">
								<el-checkbox-group v-model="checkList">
									<div>
										<el-checkbox label="复选框 A">
											<el-row type="flex" align="middle">
												<img src="https://img5.yiqifei.com/CZ.png!16x16" />
												<span>(CZ)南航</span>
											</el-row>
										</el-checkbox>
									</div>

								</el-checkbox-group>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>

				</div>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							起飞时间<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item">
							<el-dropdown-item class="drop-item">
								<el-checkbox-group v-model="checkList">

									<div>
										<el-checkbox label="复选框 B"></el-checkbox>
									</div>
									<div>
										<el-checkbox label="复选框 C"></el-checkbox>
									</div>
								</el-checkbox-group>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							降落时间<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item">
							<el-dropdown-item class="drop-item">
								<el-checkbox-group v-model="checkList">

									<div>
										<el-checkbox label="复选框 B"></el-checkbox>
									</div>
									<div>
										<el-checkbox label="复选框 C"></el-checkbox>
									</div>
								</el-checkbox-group>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							转机次数<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item">
							<el-dropdown-item class="drop-item">
								<el-checkbox-group v-model="checkList">

									<div>
										<el-checkbox label="复选框 B"></el-checkbox>
									</div>
									<div>
										<el-checkbox label="复选框 C"></el-checkbox>
									</div>
								</el-checkbox-group>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							选择机场<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item">
							<el-dropdown-item class="drop-item">
								<el-checkbox-group v-model="checkList">

									<div>
										<el-checkbox label="复选框 B"></el-checkbox>
									</div>
									<div>
										<el-checkbox label="复选框 C"></el-checkbox>
									</div>
								</el-checkbox-group>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<div class="filter-button">
					<el-dropdown :hide-on-click="false">
						<span class="el-dropdown-link">
							中转城市<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="drop-item drop-i">
							<el-dropdown-item class="drop-item">
								<div class="flexbox flex-between">
									<div>
										<span class="mr10">清晨</span>
									</div>
									<span class="iconfont icon-wancheng ft14"></span>
								</div>
							</el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div>
				<span class="empty-item">清空</span>
			</div>
			<el-row class="tap-box" type="flex">
				<div class="sort-type">
					<span class="active">低价</span>
					<span class="iconfont icon-iconpaixujiantoujiangxu icon-arrow icon-arrow-active"></span>
					<span class="iconfont icon-iconpaixujiantoushengxu icon-arrow"></span>
				</div>
				<div class="sort-type">
					<span class="">时间</span>
					<span class="iconfont icon-iconpaixujiantoujiangxu icon-arrow"></span>
					<span class="iconfont icon-iconpaixujiantoushengxu icon-arrow"></span>
				</div>
				<div class="taxbox ml15 clearfix">
					<!--当前的添加class"current"-->
					<a>不含税</a>
					<a class="current">含税</a>
				</div>
			</el-row>
		</el-row>
        </div>
	</div>
</template>

<script>
	export default {
		name: 'intlAirFliter',
		data() {
			return {
				radio: '',
				checkList: ['选中且禁用', '复选框 A']
			}
		}
	}
</script>

<style lang="scss">
	@import '../../../style/Flight/flight.scss';
</style>

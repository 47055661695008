<template>
	<div>
		<div class="searchbox" id="searchPanel">
			<div class="product-type">
				<div class="item-inp">
					<el-select v-model="TicketSearchInfo.Dual" placeholder="请选择">
						<el-option v-for="item in Duals" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<span class="add-leg-search" v-if="TicketSearchInfo.Dual == 2" @click="bindAddSegment">添加航段</span>
			</div>
			<!--多航段-->
			<div class="ticket-info" v-if="TicketSearchInfo.Dual == 2">
				<div class="info-list" v-for="(item, index) in TicketSearchInfo.MultSegments" :key="index">
					<div class="item-inp city" style="width:140px;">
						<div class="input-text" id="scrollCity'+index">
							<el-input placeholder="中文/英文/首字母" clearable suffix-icon="iconfont icon-xiangxia2" v-model="item.DepartInputVal"
							 @focus="bindShowCity($event,index, 0)" @input="searchInput" @click.stop.native ></el-input>
						</div>

					</div>
					<span class="transfer iconfont icon-qiehuan icon-change" @click="bindQieHuan(index)"></span>
					<div class="item-inp city" style="width:140px;">
						<div class="input-text" id="scrollCity'+index">
							<el-input placeholder="中文/英文/首字母" clearable suffix-icon="iconfont icon-xiangxia2" v-model="item.ArrivalInputVal"
							 @focus="bindShowCity($event,index, 1)" @input="searchInput" @click.stop.native></el-input>
						</div>

					</div>
					<div class="item-inp date" style="margin-right: 20px;">
						<div class="input-text">
							<!-- <el-input placeholder="yyyy-MM-dd" clearable suffix-icon="iconfont icon-yqfrili" :value="item.DepartDate"></el-input>-->
							<el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="yyyy-MM-dd" v-model="item.DepartDate"
							 :clearable="false" size="large" suffix-icon="iconfont icon-yqfrili" :picker-options="pickerOptions0" style="width: 146px;"></el-date-picker>
						</div>
					</div>
					<span class="del icon-chalv-guanbi iconfont icon-chalv-guanbiS" @click="bindDelSegment(index)"></span>
				</div>
			</div>
			<div class="ticket-info" v-else>
				<div class="info-list">
					<div class="item-inp city2" style="width:140px;">
						<div class="input-text" id="scrollCity">
							<el-input placeholder="中文/英文/首字母" :clearable="false" suffix-icon="iconfont icon-xiangxia2" v-model="TicketSearchInfo.DepartInputVal"
							 @focus="bindShowCity($event,0, 0)" @input="searchInput" @click.stop.native></el-input>
						</div>

					</div>
					<span class="transfer iconfont icon-qiehuan icon-change" @click="bindQieHuan(0)"></span>
					<div class="item-inp city2" style="width:140px;">
						<div class="input-text" id="scrollCity">
							<el-input placeholder="中文/英文/首字母" :clearable="false" suffix-icon="iconfont icon-xiangxia2" v-model="TicketSearchInfo.ArrivalInputVal"
							 @focus="bindShowCity($event,0, 1)" @input="searchInput" @click.stop.native></el-input>
						</div>

					</div>
					<div class="item-inp date" style="margin-right:20px;">
						<div class="input-text">
							<!-- <el-input placeholder="yyyy-MM-dd" clearable suffix-icon="iconfont icon-yqfrili" :value="TicketSearchInfo.StartDate"></el-input> -->
							<el-date-picker value-format="yyyy-MM-dd" type="date" placeholder="yyyy-MM-dd" v-model="TicketSearchInfo.StartDate"
							 :clearable="false" size="large" suffix-icon="iconfont icon-yqfrili" :picker-options="pickerOptions0" style="width: 145px;margin-right:5px;"></el-date-picker>
						</div>
					</div>
					<div class="item-inp date backdate">
						<div class="input-text">
							<!-- <el-input
								placeholder="yyyy-MM-dd"
								:disabled="TicketSearchInfo.Dual == 0"
								clearable
								suffix-icon="iconfont icon-yqfrili"
								:value="TicketSearchInfo.ReturnDate"
							></el-input> -->

							<el-date-picker :disabled="TicketSearchInfo.Dual == 0" value-format="yyyy-MM-dd" type="date" placeholder="yyyy-MM-dd"
							 v-model="TicketSearchInfo.ReturnDate" :clearable="false" size="large" suffix-icon="iconfont icon-yqfrili"
							 :picker-options="pickerOptions0" style="width: 145px;"></el-date-picker>
						</div>
					</div>
				</div>
			</div>

			<div class="rightbox">
				<div class="item-inp  product-type2" style="width:120px;">
					<el-select v-model="TicketSearchInfo.Cabin" placeholder="请选择">
						<el-option v-for="item in canbins" :key="item.value" :label="item.label" :value="item.value"></el-option>
					</el-select>
				</div>
				<el-button type="danger" icon="el-icon-search" class="mt5" @click="bindSearch">重新搜索</el-button>
			</div>
		</div>
		 <NewCity :showCity="showCity1" ref="cityAssembly" @clickCityDel="clickCityDel" @bindSelectCity="bindSelectCity"
		 hotcitytype="plane" :top="y" :left="x" @click.stop.native></NewCity>
		<!-- <calendar :showCalender="showDate" @closeCalener="closeCalener" @bindSelectDate="bindSelectDate"></calendar> -->
	</div>
</template>

<script>
	import city from '@/components/city/city.vue';
	import calendar from '@/components/calendar/calendar.vue';
	import {
		getTicketSearchInfo,
		setTicketSearchInfo
	} from '@/utils/localdatas';
	import NewCity from '@/components/newCity/newCity.vue';
	import {
		duals,
		canbins,
		adults,
		childrens,
		TicketSearchInfo
	} from '@/data/staticData';

	var util = require('@/utils/util.js');
	var ticketSearchHelper = require('@/utils/ticketSearchHelper.js');
	var datehelper = require('@/utils/datehelper.js');
	
	var destApi = require('@/api/destApi.js');

	var that;
	var index = 0;
	export default {
		name: 'intAirSearch',
		props: ['showcity'],
		components: {
			NewCity,
			// calendar
		},
		data() {
			return {
				id: '',
				showCity1: false,
				showDate: false,
				x: 0,
				y: 0,
				Duals: duals,

				canbins: canbins,
				adults: adults,
				childrens: childrens,

				// dualType: '0',
				cityIdx: 0,
				cityType: 0,
				dateIdx: 0,

				TicketSearchInfo: TicketSearchInfo,
				pickerOptions0: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				},
				pickerOptions1: {
					disabledDate(time) {
						return time.getTime() < Date.now() - 8.64e7;
					}
				}
			};
		},
		mounted() {
			window.addEventListener('scroll', this.handleScroll) // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
			document.addEventListener('click', (e) => {
				that.showCity1 = false;
			})
		},
		created() {
			that = this;
			var query = that.$route.query;
			console.log(query);
			that.TicketSearchInfo = ticketSearchHelper.GetTicketSearchInfo();
			// that.dualType=that.TicketSearchInfo.Dual;
			console.log(that.TicketSearchInfo);
			// getSearchFSJobid();
		},
		methods: {
			on2: function() {
				this.showCity1 = false;

			},
			handleScroll() {
				try {
				if (that.TicketSearchInfo.Dual == 2) {
					var rect = document.querySelector('#scrollCity' + that.cityIdx).getBoundingClientRect();
				} else {
					var rect = document.querySelector('#scrollCity').getBoundingClientRect();
				}
				this.y = rect.top;
				} catch (e) {
				
				}
				// console.log(window.pageYOffset + "1");
				// console.log(this.y);
			},
			searchInput(e) {
				console.log(e);
				this.$refs.cityAssembly.searchInput(e);
			},
			//搜索航司
			queryAirlineSearch(queryString, cb) {
				var parm = {
					keyword: queryString
				};
				destApi.GetAirlineList(parm, function(result) {
					console.log(result);
					if (result.code == 0) {
						var airlineList = result.data.airlineList;
						cb(airlineList);
					} else {
						cb([]);
					}
				});
			},
			handleSelect(item) {
				console.log(item);
				// that.airlineVal=item.
				// that.formInline.airine=item.code;
				that.TicketSearchInfo.Airline = item.code;
			},
			//选择城市
			bindShowCity(e, cityIdx, cityType) {
				console.log(cityIdx, cityType);
				that.cityIdx = cityIdx;
				that.cityType = cityType;
				this.showCity1 = true;


				var rect = e.target.getBoundingClientRect();
				this.x = rect.left
				this.y = rect.top;
				console.log(window.pageYOffset + "1");
				console.log(this.y);
				this.classType = e.target.offsetParent.className;
				e.currentTarget.select();
			},
			clickCityDel() {
				this.showCity1 = false;
			},
			//切换城市
			bindQieHuan(idx) {
				that.TicketSearchInfo=ticketSearchHelper.QieHuan(that.TicketSearchInfo,idx);				
			},
			//选择城市
			bindSelectCity(cityModel) {
				that.showCity1 = false;
				var cityIdx = that.cityIdx;
				var cityType=that.cityType;
				console.log(cityModel);
				console.log(cityIdx);
				console.log(cityType);
							
				that.TicketSearchInfo=ticketSearchHelper.SelectCity(that.TicketSearchInfo,cityType,cityIdx,cityModel);
			},
			//选择日期
			showCalendar(dateIdx) {
				console.log(dateIdx);
				if (that.TicketSearchInfo.Dual == 0 && dateIdx == 1) {
					return;
				}
				that.dateIdx = dateIdx;
				this.showDate = true;
			},
			closeCalener() {
				this.showDate = false;
			},
			//选择日期
			bindSelectDate(dateModel) {
				that.showDate = false;
				that.TicketSearchInfo = ticketSearchHelper.SelectDate( that.TicketSearchInfo,that.dateIdx,dateModel);
			},
			//添加航段
			bindAddSegment() {			
				that.TicketSearchInfo=ticketSearchHelper.AddSegment(that.TicketSearchInfo);
			},
			bindDelSegment(idx) {			
				that.TicketSearchInfo=ticketSearchHelper.DelSegment(that.TicketSearchInfo,idx);
			},
			//搜索
			bindSearch: function(e) {
				var TicketSearchInfo = that.TicketSearchInfo;
				// TicketSearchInfo.Dual=that.dualType;
				util.postAjaxRequest('/FlightAPI/GetTicketSearchInfo', {
					ticketsearchinfo: JSON.stringify(TicketSearchInfo)
				}, function(result) {
					console.log(result);
					if (result.code == 0) {
						TicketSearchInfo = result.data.TicketSearchInfo;
						console.log(TicketSearchInfo);
						setTicketSearchInfo(TicketSearchInfo);
						that.$emit('bindSearchFlight', TicketSearchInfo);
						// if (TicketSearchInfo.IsChinaCity) {
						// 	that.$router.push({
						// 		path: '/Flight/ShowDomesticFlight'
						// 	});
						// } else {
						// 	// that.$router.push({ path: '/IntlAir/list' });
						// 	that.$emit('bindSearchFlight', TicketSearchInfo);
						// }
					} else {
						that.$message.error(result.msg);
					}
				});
			}
		}
	};
</script>

<style>
	.guding {
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 9;
	}
</style>
